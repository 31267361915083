import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { advancedMessages } from "../../../../utils/config";
import { nodeId, nodeModes } from "../../config";

class MessageSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchFeature: "",
			filteredMessages: advancedMessages,
		};
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClickOutside);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleClickOutside);
	};

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef = (node) => {
		setTimeout(() => {
			window.wrapperRef = node;
		}, 100);
	};

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside = (event) => {
		if (window.wrapperRef && !window.wrapperRef.contains(event.target)) {
			this.props.removeComponentNode(nodeId.DRAFT);
		}
	};

	selectOption = (mode) => {
		const { getNode } = this.props.toolDiagram;
		const node = getNode(nodeId.DRAFT);

		this.props.createNodeByMessageSelection(mode, {
			x: node.position.x,
			y: node.position.y,
		});
	};

	handleInputClick = () => {
		const inputElement = document.getElementsByClassName(
			"message-selection-search-input"
		)[0];

		if (inputElement) {
			inputElement.focus();
		}
	};

	handleInputChange = (event, t) => {
		this.setState({ searchFeature: event.target.value });
		const filteredEssentials = this.filterMessages(
			event.target.value,
			advancedMessages,
			t
		);

		if (Object.keys(filteredEssentials).length === 0) {
			this.setState({ filteredMessages: advancedMessages });
		} else {
			this.setState({ filteredMessages: filteredEssentials });
		}
	};

	// Function to filter messages by a partial match of the name
	filterMessages = (input, messages, t) => {
		const filteredMessages = {};

		for (const category in messages) {
			const categoryInfo = messages[category];
			const filteredCategory = { ...categoryInfo, messages: {} };

			for (const messageKey in categoryInfo.messages) {
				const message = categoryInfo.messages[messageKey];
				if (
					t(`messageNameAndDescription.${message.slug}.name`)
						.toLowerCase()
						.includes(input.toLowerCase())
				) {
					filteredCategory.messages[messageKey] = message;
				}
			}

			if (Object.keys(filteredCategory.messages).length > 0) {
				filteredMessages[category] = filteredCategory;
			}
		}

		return { ...filteredMessages };
	};

	render() {
		const { selectedApp, t } = this.props;

		return (
			<div
				className="message-selection-container custom-drag-handle"
				ref={this.setWrapperRef}
			>
				<div className="body">
					<div className="message-selection-search">
						<label className="message-selection-search-header">
							{t(`typeTosearch`)}
						</label>
						<input
							className="message-selection-search-input"
							type="text"
							placeholder={t(`typeSearch`)}
							value={this.state.searchFeature}
							onChange={(e) => this.handleInputChange(e, t)}
							onClick={this.handleInputClick}
						/>
					</div>
					<div className="message-selection-features nowheel">
						{Object.values(this.state.filteredMessages).map((category) => {
							const supportedMessages = Object.entries(
								category.messages
							).filter(([key, message]) => {
								let countTrue = 0;
								for (
									let index = 0;
									index < selectedApp.platform.length;
									index++
								) {
									const platformName = selectedApp.platform[index];
									if (_.get(message, `platform.${platformName}`)) {
										countTrue += 1;
									}
								}
								return selectedApp.platform.length === countTrue;
							});

							if (supportedMessages.length === 0) return <div></div>;

							const messages = supportedMessages
								.filter(
									([key, message]) =>
										key !== "INTRO" && key !== nodeModes.DIAGRAM_GROUP
								)
								.map(([key, message], index) => (
									<button
										key={index}
										className="transparent message-option-container"
										onClick={() => this.selectOption(key)}
									>
										<div className="image-container">
											{!message.icon ? (
												<img
													alt=""
													src={message.image}
													className="img img-loaded"
												/>
											) : (
												message.icon
											)}
										</div>
										<div className="message-option-item">
											<p className="title">
												{t(`messageNameAndDescription.${message.slug}.name`)}
											</p>
											<p className="description">
												{t(
													`messageNameAndDescription.${message.slug}.description`
												)}
											</p>
										</div>
									</button>
								));

							return (
								<div key={category.title}>
									<div className="header">
										<p>{t(`category.${category.name}`)}</p>
									</div>
									{messages}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation("editMessage")(MessageSelection);

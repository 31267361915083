import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* fetchPlatformConfig() {
	while (true) {
		yield take(types.FETCH_PLATFORM_CONFIG)
		try {
            yield put(actions.isFetchingPlatformConfig(true))
			const data = yield call(api.fetchPlatformConfig)
            yield put(actions.platformConfig(data))
            yield put(actions.isFetchingPlatformConfig(false))
		} catch (err) {
            yield put(actions.isFetchingPlatformConfig(false))
			console.error('[fetchPlatformConfig] ', err)
			eventlog.error('fetch platform config', {
				message: err.message
			})
		}
	}
}

export default fetchPlatformConfig

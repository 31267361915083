import * as types from "./types";

export const startApp = () => ({
	type: types.START_APP,
});

export const fetchMessageConfig = () => ({
	type: types.FETCH_MESSAGE_CONFIG,
});

export const newMessage = (newMessage, isInit = false) => ({
	type: types.NEW_MESSAGE,
	payload: {
		newMessage,
		isInit,
	},
});

export const createNewMessageError = (error) => ({
	type: types.CREATE_NEW_MESSAGE_ERROR,
	payload: {
		error,
	},
});

export const signin = () => ({
	type: types.SIGNIN,
});

export const loginForm = (email, password) => ({
	type: types.LOGIN_FORM,
	payload: {
		loginForm: {
			email,
			password,
		},
	},
});

export const signinError = (error) => ({
	type: types.SIGNIN_ERROR,
	payload: {
		error,
	},
});

export const signinLoading = (loading) => ({
	type: types.SIGNIN_LOADING,
	payload: {
		loading,
	},
});

export const messageProperties = (index, message) => ({
	type: types.MESSAGE_PROPERTIES,
	payload: {
		index,
		message,
	},
});

export const configuration = (config) => ({
	type: types.CONFIGURATION,
	payload: {
		config,
	},
});

export const startNodeConfig = (config) => ({
	type: types.START_NODE_CONFIG,
	payload: {
		config,
	},
});

export const logout = () => ({
	type: types.LOGOUT,
});

export const onEdit = (id) => ({
	type: types.ON_EDIT,
	payload: {
		id,
	},
});

export const editMessage = (editMessage) => ({
	type: types.EDIT_MESSAGE,
	payload: {
		editMessage,
	},
});

export const humantakeover = (payload) => ({
	type: types.HUMAN_TAKEOVER,
	payload,
});

export const onPeriodTimerPreviewChange = (period) => ({
	type: types.PREVIEW_PERIOD_TIMER_CHANGE,
	payload: {
		period,
	},
});

export const onPreviewTimeLeftChange = (timeLeft) => ({
	type: types.PREVIEW_TIME_LEFT_CHANGE,
	payload: {
		timeLeft,
	},
});

export const onPostMessageToPreviewWindow = () => ({
	type: types.ON_POST_MESSAGE_TO_PREVIEW_WINDOW,
});

export const onSelectStep = (step, type, finishedStep) => ({
	type: types.ON_SELECT_STEP,
	payload: {
		step,
		type,
		finishedStep,
	},
});

export const onSelectApp = (appId) => ({
	type: types.ON_SELECT_APP,
	payload: {
		appId,
	},
});

export const fetchApps = () => ({
	type: types.FETCH_APPS,
});

export const apps = (apps) => ({
	type: types.APPS,
	payload: {
		apps,
	},
});

export const isSavingPreview = (status) => ({
	type: types.IS_SAVING_PREVIEW,
	payload: {
		status,
	},
});

export const fetchEditModal = () => ({
	type: types.FETCH_EDIT_MODAL,
});

export const editModalConfig = (editModalConfig) => ({
	type: types.EDIT_MODAL_CONFIG,
	payload: {
		editModalConfig,
	},
});

export const saveEditModalConfig = () => ({
	type: types.SAVE_EDIT_MODAL_CONFIG,
});

export const ecommerceConfig = (config) => ({
	type: types.ECOMMERCE_CONFIG,
	payload: {
		config,
	},
});

export const updateECommerceConfig = () => ({
	type: types.UPDATE_ECOMMERCE_CONFIG,
});

export const createNewBot = () => ({
	type: types.CREATE_NEW_BOT,
});

export const enterBotManagement = () => ({
	type: types.ENTER_BOT_MANAGEMENT,
});

export const selectedApp = (app) => ({
	type: types.SELECTED_APP,
	payload: {
		app,
	},
});

export const draftAppConfig = (app) => ({
	type: types.DRAFT_APP_CONFIG,
	payload: {
		app,
	},
});

export const onFetchTemplateConfig = (templates) => ({
	type: types.TEMPLATE_CONFIG,
	payload: {
		templates,
	},
});

export const enterAppManagement = () => ({
	type: types.ENTER_APP_MANAGEMENT,
});

export const onFetchDraftAppConfig = () => ({
	type: types.ON_FETCH_DRAFT_APP_CONFIG,
});

export const saveAppConfig = () => ({
	type: types.SAVE_APP_CONFIG,
});

export const updateSaveConfigLoading = (status) => ({
	type: types.UPDATE_SAVE_CONFIG_LOADING,
	payload: {
		status,
	},
});

export const fetchResponses = (visitorId) => ({
	type: types.FETCH_RESPONSES,
	payload: {
		visitorId,
	},
});

export const responses = (responses) => ({
	type: types.RESPONSES,
	payload: {
		responses,
	},
});

export const fetchResponsesGroupByDevice = () => ({
	type: types.FETCH_RESPONSES_GROUP_BY_DEVICE,
});

export const responsesGroupByDevice = (responsesGroupByDevice) => ({
	type: types.RESPONSES_GROUP_BY_DEVICE,
	payload: {
		responsesGroupByDevice,
	},
});

export const isResponsesLoading = (status) => ({
	type: types.IS_RESPONSES_LOADING,
	payload: {
		status,
	},
});

export const isConfigLoading = (status) => ({
	type: types.IS_CONFIG_LOADING,
	payload: {
		status,
	},
});

export const removedMessages = (messages) => ({
	type: types.REMOVED_MESSAGES,
	payload: {
		messages,
	},
});

export const fetchRemovedMessages = () => ({
	type: types.FETCH_REMOVED_MESSAGES,
});

export const isAppsLoading = (status) => ({
	type: types.IS_APPS_LOADING,
	payload: {
		status,
	},
});

export const isBotManagementLoading = (status) => ({
	type: types.IS_BOT_MANAGEMENT_LOADING,
	payload: {
		status,
	},
});

export const startOver = (config) => ({
	type: types.START_OVER,
	payload: {
		config,
	},
});

export const humanTakeoverComplete = (appId, roomId) => ({
	type: types.HUMAN_TAKEOVER_COMPLETE,
	payload: {
		appId,
		roomId,
	},
});

export const humanTakeoverStart = (appId, roomId) => ({
	type: types.HUMAN_TAKEOVER_START,
	payload: {
		appId,
		roomId,
	},
});

export const humanTakeoverIgnore = (appId, roomId) => ({
	type: types.HUMAN_TAKEOVER_IGNORE,
	payload: {
		appId,
		roomId,
	},
});

export const onSelectNode = (message) => ({
	type: types.ON_SELECT_NODE,
	payload: {
		message,
	},
});

export const isHasIntro = (status) => ({
	type: types.IS_HAS_INTRO,
	payload: {
		status,
	},
});

export const isShowCreateNewBotModal = (isShow) => ({
	type: types.IS_SHOW_CREATE_NEW_BOT_MODAL,
	payload: {
		isShow,
	},
});

export const params = (params) => ({
	type: types.PARAMS,
	payload: {
		params,
	},
});

export const toast = (toast) => ({
	type: types.TOAST,
	payload: {
		toast,
	},
});

export const draftNewBot = (data) => ({
	type: types.DRAFT_NEW_BOT,
	payload: {
		data,
	},
});

export const toggleNodeMenu = () => ({
	type: types.TOGGLE_NODE_MENU,
});

export const isSaving = (isSaving) => ({
	type: types.IS_SAVING,
	payload: {
		isSaving,
	},
});

export const draftUploadMedia = (draftUploadMedia) => ({
	type: types.DRAFT_UPLOAD_MEDIA,
	payload: {
		draftUploadMedia: draftUploadMedia,
	},
});

export const saveUploadMedia = () => ({
	type: types.SAVE_UPLOAD_MEDIA,
});

export const onEditMap = (data) => ({
	type: types.ON_EDIT_MAP,
	payload: {
		data,
	},
});

export const onCloseEditMap = () => ({
	type: types.ON_CLOSE_EDIT_MAP,
});

export const onConfirmEditMap = (location) => ({
	type: types.ON_CONFIRM_EDIT_MAP,
	payload: {
		location,
	},
});

export const deleteApp = (appId) => ({
	type: types.DELETE_APP,
	payload: {
		appId,
	},
});

export const deletingApp = (isDeleting) => ({
	type: types.IS_DELETING_APP,
	payload: {
		isDeleting,
	},
});

export const copyApp = (appId) => ({
	type: types.COPY_APP,
	payload: {
		appId,
	},
});

export const copyingApp = (isCopying) => ({
	type: types.IS_COPYING_APP,
	payload: {
		isCopying,
	},
});

export const register = (register) => ({
	type: types.REGISTER,
	payload: {
		register,
	},
});

export const registerLoading = (loading) => ({
	type: types.REGISTER_LOADING,
	payload: {
		loading,
	},
});

export const registerError = (error) => ({
	type: types.REGISTER_ERROR,
	payload: {
		error,
	},
});

export const isRegisterSuccess = (status) => ({
	type: types.IS_REGISTER_SUCCESS,
	payload: {
		status,
	},
});

export const checkingAuth = () => ({
	type: types.CHECKING_AUTH,
});

export const isShowingSplash = (isShowing) => ({
	type: types.IS_SHOWING_SPLASH,
	payload: {
		isShowing: isShowing,
	},
});

export const isSavingPreviewInBackground = (status) => ({
	type: types.IS_SAVING_PREVIEW_IN_BACKGROUND,
	payload: {
		status,
	},
});

export const design = (design) => ({
	type: types.DESIGN,
	payload: {
		design,
	},
});

export const fetchDesignConfig = () => ({
	type: types.FETCH_DESIGN_CONFIG,
});

export const postMessageToIframe = (iframeId, source, data) => ({
	type: types.POST_MESSAGE_TO_IFRAME,
	payload: {
		iframeId,
		source,
		data,
	},
});

export const fetchSettings = () => ({
	type: types.FETCH_SETTINGS,
});

export const saveStartOver = () => ({
	type: types.SAVE_START_OVER,
});

export const isRequestingResetPassword = (isRequesting) => ({
	type: types.IS_REQUESTING_RESET_PASSWORD,
	payload: {
		isRequesting,
	},
});

export const forgotPasswordError = (error) => ({
	type: types.FORGOT_PASSWORD_ERROR,
	payload: {
		error,
	},
});

export const isForgotPasswordSuccess = (isSuccess) => ({
	type: types.IS_FORGOT_PASSWORD_SUCCESS,
	payload: {
		isSuccess,
	},
});

export const forgotPasswordForm = (form) => ({
	type: types.FORGOT_PASSWORD_FORM,
	payload: {
		form,
	},
});

export const requestResetPassword = () => ({
	type: types.REQUEST_RESET_PASSWORD,
});

export const isResettingPassword = (isResetting) => ({
	type: types.IS_RESETTING_PASSWORD,
	payload: {
		isResetting,
	},
});

export const resetPasswordError = (error) => ({
	type: types.RESET_PASSWORD_ERROR,
	payload: {
		error,
	},
});

export const isResetPasswordSuccess = (isSuccess) => ({
	type: types.IS_RESET_PASSWORD_SUCCESS,
	payload: {
		isSuccess,
	},
});

export const resetPasswordForm = (form) => ({
	type: types.RESET_PASSWORD_FORM,
	payload: {
		form,
	},
});

export const resetPassword = () => ({
	type: types.RESET_PASSWORD,
});

export const fetchPublicData = () => ({
	type: types.FETCH_PUBLIC_DATA,
});

export const credentials = (credentials) => ({
	type: types.CREDENTIALS,
	payload: {
		credentials,
	},
});

export const onEnterDistribute = () => ({
	type: types.ON_ENTER_DISTRIBUTE,
});

export const isDistributeLoading = (status) => ({
	type: types.IS_DISTRIBUTE_LOADING,
	payload: {
		status,
	},
});

export const sharedToken = (token) => ({
	type: types.SHARED_TOKEN,
	payload: {
		token,
	},
});

export const renewToken = () => ({
	type: types.RENEW_TOKEN,
});

export const startAppChat = (query = "all") => ({
	type: types.START_APP_CHAT,
	payload: {
		query,
	},
});

export const startIntegrationChat = (query = "all") => ({
	type: types.START_INTEGRATION_CHAT,
	payload: {
		query,
	},
});

export const visitors = (visitors) => ({
	type: types.VISITORS,
	payload: {
		visitors,
	},
});

export const visitorMessages = (messages) => ({
	type: types.VISITOR_MESSAGES,
	payload: {
		messages,
	},
});

export const messengerManager = (manager) => ({
	type: types.MESSENGER_MANAGER,
	payload: {
		manager,
	},
});

export const presenceVisitors = (presence) => ({
	type: types.PRESENCE_VISITORS,
	payload: {
		presence,
	},
});

export const onSelectMessengerVisitor = (appId, visitor) => ({
	type: types.ON_SELECT_MESSENGER_VISITOR,
	payload: {
		appId,
		visitor,
	},
});

export const selectedVisitor = (visitor) => ({
	type: types.SELECTED_VISITOR,
	payload: {
		visitor,
	},
});

export const onSendMessage = (visitor, message) => ({
	type: types.ON_SEND_MESSAGE,
	payload: {
		visitor,
		message,
	},
});

export const onFetchOlderMessages = (appId, visitor, oldestMessageId) => ({
	type: types.ON_FETCH_OLDER_MESSAGES,
	payload: {
		appId,
		visitor,
		oldestMessageId,
	},
});

export const isLoadingOlderMessages = (status) => ({
	type: types.IS_LOADING_OLDER_MESSAGES,
	payload: {
		status,
	},
});

export const hasMoreMessages = (status) => ({
	type: types.HAS_MORE_MESSAGES,
	payload: {
		status,
	},
});

export const hasMoreVisitors = (status) => ({
	type: types.HAS_MORE_VISITORS,
	payload: {
		status,
	},
});

export const isLoadingMessages = (status) => ({
	type: types.IS_LOADING_MESSAGES,
	payload: {
		status,
	},
});

export const isLoadingVisitors = (status) => ({
	type: types.IS_LOADING_VISITORS,
	payload: {
		status,
	},
});

export const onLoadMoreAppVisitors = (query, fromId) => ({
	type: types.ON_LOAD_MORE_APP_VISITORS,
	payload: {
		fromId,
		query,
	},
});

export const onLoadMoreIntegrationVisitors = (query, fromId) => ({
	type: types.ON_LOAD_MORE_INTEGRATION_VISITORS,
	payload: {
		fromId,
		query,
	},
});

export const isLoadingMoreVisitors = (status) => ({
	type: types.IS_LOADING_MORE_VISITORS,
	payload: {
		status,
	},
});

export const unsubcribeChannel = (name) => ({
	type: types.UNSUBCRIBE_CHANNEL,
	payload: {
		name,
	},
});

export const failedMessages = (messages) => ({
	type: types.FAILED_MESSAGES,
	payload: {
		messages,
	},
});

export const retryMessage = (message) => ({
	type: types.RETRY_MESSAGE,
	payload: {
		message,
	},
});

export const deleteMessage = (message) => ({
	type: types.DELETE_MESSAGE,
	payload: {
		message,
	},
});

export const isFetchingResponsesStats = (status) => ({
	type: types.IS_FETCHING_RESPONSES_STATS,
	payload: {
		status,
	},
});

export const fetchResponsesStats = () => ({
	type: types.FETCH_RESPONSES_STATS,
});

export const responsesStats = (responsesStats) => ({
	type: types.RESPONSES_STATS,
	payload: {
		responsesStats,
	},
});

export const isFetchingResponse = (status) => ({
	type: types.IS_FETCHING_RESPONSE,
	payload: {
		status,
	},
});

export const fetchResponse = (responseId) => ({
	type: types.FETCH_RESPONSE,
	payload: {
		responseId,
	},
});

export const fetchAnalytic = (start, end) => ({
	type: types.FETCH_ANALYTIC,
	payload: {
		start,
		end,
	},
});

export const fetchDashboard = (start, end, filter) => ({
	type: types.FETCH_DASHBOARD,
	payload: {
		start,
		end,
		filter,
	},
});

export const isFetchingDashboard = (status) => ({
	type: types.IS_FETCHING_DASHBOARD,
	payload: {
		status,
	},
});

export const response = (response) => ({
	type: types.RESPONSE,
	payload: {
		response,
	},
});

export const onSearchCollaborators = (query) => ({
	type: types.ON_SEARCH_COLLABORATORS,
	payload: {
		query,
	},
});

export const collaborators = (collaborators) => ({
	type: types.COLLABORATORS,
	payload: {
		collaborators,
	},
});

export const collaboratorsSearchResult = (collaborators) => ({
	type: types.COLLABORATORS_SEARCH_RESULT,
	payload: {
		collaborators,
	},
});

export const isSearchingCollaborators = (status) => ({
	type: types.IS_SEACHING_COLLABORATORS,
	payload: {
		status,
	},
});

export const onEnterCollaboratorsPage = () => ({
	type: types.ON_ENTER_COLLABORATORS_PAGE,
});

export const isFetchingCollaborators = (status) => ({
	type: types.IS_FETCHING_COLLABORATORS,
	payload: {
		status,
	},
});

export const sendCollaboratorInvitation = (email, role) => ({
	type: types.SEND_COLLABORATOR_INVATATION,
	payload: {
		email,
		role,
	},
});

export const isInvitingCollaborator = (status) => ({
	type: types.IS_INVITING_COLLABORATOR,
	payload: {
		status,
	},
});

export const acceptInvitation = (userID) => ({
	type: types.ACCEPT_INVITATION,
	payload: {
		userID,
	},
});

export const declineInvitation = (userID) => ({
	type: types.DECLINE_INVITATION,
	payload: {
		userID,
	},
});

export const accessControls = (accessControls) => ({
	type: types.ACCESS_CONTROLS,
	payload: {
		accessControls,
	},
});

export const enterInvitationPage = () => ({
	type: types.ENTER_INVITATION_PAGE,
});

export const isShowingInvitationModal = (status) => ({
	type: types.IS_SHOWING_INVITATION_MODAL,
	payload: {
		status,
	},
});

export const linkWithGoogle = () => ({
	type: types.LINK_WITH_GOOGLE,
});

export const googleLinkingUrl = (url) => ({
	type: types.GOOGLE_LINKING_URL,
	payload: {
		url,
	},
});

export const unlinkWithGoogle = () => ({
	type: types.UNLINK_WITH_GOOGLE,
});

export const googleLinkingDetail = (detail) => ({
	type: types.GOOGLE_LINKING_DETAIL,
	payload: {
		detail,
	},
});

export const fetchGoogleLinkingDetail = () => ({
	type: types.FETCH_GOOGLE_LINKING_DETAIL,
});

export const googleSpreadsheets = (spreadsheets) => ({
	type: types.GOOGLE_SPREADSHEETS,
	payload: {
		spreadsheets,
	},
});

export const typingEmulation = (config) => ({
	type: types.TYPING_EMULATION,
	payload: {
		config,
	},
});

export const mobileApp = (config) => ({
	type: types.MOBILE_APP,
	payload: {
		config,
	},
});

export const saveTypingEmulation = () => ({
	type: types.SAVE_TYPING_EMULATION,
});

export const isValidatingSlug = (status) => ({
	type: types.IS_VALIDATING_SLUG,
	payload: {
		status,
	},
});

export const validateSlug = () => ({
	type: types.VALIDATE_SLUG,
});

export const mobileAppConfig = (config) => ({
	type: types.MOBILE_APP_CONFIG,
	payload: {
		config,
	},
});

export const saveMobileAppConfig = (config) => ({
	type: types.SAVE_MOBILE_APP_CONFIG,
});

export const isGeneratingMobileAppConfig = (status) => ({
	type: types.IS_GENERATING_MOBILE_APP_CONFIG,
	payload: {
		status,
	},
});

export const isSavingMobileAppConfig = (status) => ({
	type: types.IS_SAVING_MOBILE_APP_CONFIG,
	payload: {
		status,
	},
});

export const mobileAppBuilds = (mobileAppBuilds) => ({
	type: types.MOBILE_APP_BUILDS,
	payload: {
		mobileAppBuilds,
	},
});

export const redirect = (url) => ({
	type: types.REDIRECT,
	payload: {
		url,
	},
});

export const isPublishingApp = (status) => ({
	type: types.IS_PUBLISHING_APP,
	payload: {
		status,
	},
});

export const publishApp = () => ({
	type: types.PUBLISH_APP,
});

export const editMessageErrors = (errors) => ({
	type: types.EDIT_MESSAGE_ERRORS,
	payload: {
		errors,
	},
});
export const fetchModuleResponses = () => ({
	type: types.FETCH_MODULE_RESPONSES,
});

export const enterModuleResponseDetail = (messageId) => ({
	type: types.ENTER_MODULE_RESPONSE_DETAIL,
	payload: {
		messageId,
	},
});

export const moduleResponseDetail = (detail) => ({
	type: types.MODULE_RESPONSE_DETAIL,
	payload: {
		detail,
	},
});

export const analyticData = (data) => ({
	type: types.ANALYTIC_DATA,
	payload: {
		data,
	},
});

export const dashboardData = (data) => ({
	type: types.DASHBOARD_DATA,
	payload: {
		data,
	},
});

export const isFetchingModuleResponses = (status) => ({
	type: types.IS_FETCHING_MODULE_RESPONSES,
	payload: {
		status,
	},
});

export const moduleResponses = (moduleResponses) => ({
	type: types.MODULE_RESPONSES,
	payload: {
		moduleResponses,
	},
});

export const fetchProducts = () => ({
	type: types.FETCH_PRODUCTS,
});

export const isFetchingProducts = (status) => ({
	type: types.IS_FETCHING_PRODUCTS,
	payload: {
		status,
	},
});

export const createProduct = (product) => ({
	type: types.CREATE_PRODUCT,
	payload: {
		product,
	},
});

export const updateProduct = (product) => ({
	type: types.UPDATE_PRODUCT,
	payload: {
		product,
	},
});

export const deleteProduct = () => ({
	type: types.DELETE_PRODUCT,
});

export const products = (products) => ({
	type: types.PRODUCTS,
	payload: {
		products,
	},
});

export const enableFeatureFlag = (flag) => ({
	type: types.ENABLE_FEATURE_FLAG,
	payload: {
		flag,
	},
});

export const enablingFeatureFlag = (flags) => ({
	type: types.ENABLING_FEATURE_FLAG,
	payload: {
		flags,
	},
});

export const featureFlags = (featureFlags) => ({
	type: types.FEATURE_FLAGS,
	payload: {
		featureFlags,
	},
});

export const unauthorization = () => ({
	type: types.UNAUTHORZIATION,
});

export const variables = (variables) => ({
	type: types.VARIABLES,
	payload: {
		variables,
	},
});

export const fetchVariables = () => ({
	type: types.FETCH_VARIABLES,
});

export const saveVariables = () => ({
	type: types.SAVE_VARIABLES,
});

export const fetchMobileAppBuild = () => ({
	type: types.FETCH_MOBILE_APP_BUILDS,
});

export const autoGenMobileAppConfig = () => ({
	type: types.AUTO_GEN_MOBILE_APP_CONFIG,
});

export const authData = (data) => ({
	type: types.AUTH_DATA,
	payload: {
		data,
	},
});

export const enterSubscription = () => ({
	type: types.ENTER_SUBSCRIPTION,
});

export const selectedPlan = (plan) => ({
	type: types.SELECTED_PLAN,
	payload: plan,
});

export const allPlans = (plans) => ({
	type: types.ALL_PLANS,
	payload: plans,
});

export const currentSchedule = (currentSchedule) => ({
	type: types.CURRENT_SCHEDULE,
	payload: currentSchedule,
});

export const currentCards = (cards) => ({
	type: types.CURRENT_CARDS,
	payload: cards,
});

export const draftCard = (card) => ({
	type: types.DRAFT_CARD,
	payload: card,
});

export const addCard = () => ({
	type: types.ADD_CARD,
});

export const editCard = (name, year, month) => ({
	type: types.EDIT_CARD,
	payload: {
		name,
		year,
		month,
	},
});

export const isAddingCard = (isAddingCard) => ({
	type: types.IS_ADDING_CARD,
	payload: isAddingCard,
});

export const addCardError = (error) => ({
	type: types.ADD_CARD_ERROR,
	payload: error,
});

export const changePlan = (planID, billingCycle) => ({
	type: types.CHANGE_PLAN,
	payload: {
		planID,
		billingCycle,
	},
});

export const isSubscriptionPageLoading = (loading) => ({
	type: types.IS_SUBSCIPTION_PAGE_LOADING,
	payload: loading,
});

export const isFetchingLineCustomer = (status) => ({
	type: types.IS_FETCHING_LINE_CUSTOMER,
	payload: {
		status,
	},
});

export const lineCustomer = (lineCustomer) => ({
	type: types.LINE_CUSTOMER,
	payload: {
		lineCustomer,
	},
});

export const banLineCustomer = (id, limit, offset, search) => ({
	type: types.BAN_LINE_CUSTOMER,
	payload: {
		id,
		limit,
		offset,
		search,
	},
});

export const fetchLineCustomerWithQuery = (limit, offset, search) => ({
	type: types.LINE_CUSTOMER_QUERY,
	payload: {
		limit,
		offset,
		search,
	},
});

export const isShowingTrackingMessageModal = (status) => ({
	type: types.IS_SHOWING_TRACKING_MESSAGE_MODAL,
	payload: {
		status,
	},
});

export const flexErrorPath = (error) => ({
	type: types.FLEX_ERROR,
	payload: {
		error,
	},
});

export const onSaveImageSizeError = (error) => ({
	type: types.IMAGE_SIZE_ERROR,
	payload: {
		error,
	},
});

export const storeUserId = (userID) => ({
	type: types.STORE_USER_ID,
	payload: {
		userID,
	},
});

export const fetchRoles = (platform) => ({
	type: types.FETCH_ROLES,
	payload: {
		platform,
	},
});

export const roles = (roles) => ({
	type: types.ROLES,
	payload: {
		roles,
	},
});

export const isFetchingRoles = (status) => ({
	type: types.IS_FETCHING_ROLES,
	payload: {
		status,
	},
});

export const generateInvitationLink = (role) => ({
	type: types.GENERATE_INVITATION_LINK,
	payload: {
		role,
	},
});

export const storeInvitationURL = (url) => ({
	type: types.STORE_INVITATION_URL,
	payload: {
		url,
	},
});

export const isShowingAddPaymentMethod = (status) => ({
	type: types.IS_SHOWING_ADD_PAYMENT_METHOD,
	payload: status,
});

export const isShowingEditPaymentMethod = (status) => ({
	type: types.IS_SHOWING_EDIT_PAYMENT_METHOD,
	payload: status,
});

export const onSelectPlansPlatfromChange = (plansPlatform) => ({
	type: types.ON_SELECT_PLANS_PLATFORM_CHANGE,
	payload: plansPlatform,
});

export const onDeleteCard = (cardId) => ({
	type: types.ON_DELETE_CARD,
	payload: {
		cardId,
	},
});

export const storeBillingInformation = (billingInformation) => ({
	type: types.STORE_BILLING_INFORMATION,
	payload: billingInformation,
});

export const onSaveBillingInformation = () => ({
	type: types.ON_SAVE_BILLING_INFORMATION,
});

export const onBillingInformationError = (errors) => ({
	type: types.BILLING_INFORMATION_ERROR,
	payload: {
		errors,
	},
});

export const onCardError = (errors) => ({
	type: types.CARD_ERROR,
	payload: {
		errors,
	},
});

export const onEnterBillingInformation = () => ({
	type: types.ON_ENTER_BILLING_INFORMATION,
});

export const onChangingPlan = (status) => ({
	type: types.ON_CHANGING_PLAN,
	payload: status,
});

export const storeBillingCycle = (billingCycle) => ({
	type: types.STORE_BILLING_CYCLE,
	payload: billingCycle,
});

export const storeUpgradePlan = (upgradePlan) => ({
	type: types.STORE_UPGRADE_PLAN,
	payload: upgradePlan,
});

export const fetchLineInsightFriendQuery = (
	start_date,
	end_date,
	limit,
	offset,
	search
) => ({
	type: types.LINE_INSIGHT_FRIEND_QUERY,
	payload: {
		start_date,
		end_date,
		limit,
		offset,
		search,
	},
});

export const fetchLineInsightMessageQuery = (
	start_date,
	end_date,
	limit,
	offset,
	search
) => ({
	type: types.LINE_INSIGHT_MESSAGE_QUERY,
	payload: {
		start_date,
		end_date,
		limit,
		offset,
		search,
	},
});

export const isFetchingLineInsightFriend = (status) => ({
	type: types.IS_FETCHING_LINE_INSIGHT_FRIEND,
	payload: {
		status,
	},
});

export const isFetchingLineInsightMessage = (status) => ({
	type: types.IS_FETCHING_LINE_INSIGHT_MESSAGE,
	payload: {
		status,
	},
});

export const lineInsightFriend = (insightFriend) => ({
	type: types.LINE_INSIGHT_FRIEND,
	payload: {
		insightFriend,
	},
});

export const lineInsightMessage = (insightMessage) => ({
	type: types.LINE_INSIGHT_MESSAGE,
	payload: {
		insightMessage,
	},
});

export const dataFeedSelect = (data) => ({
	type: types.DATA_FEED_SELECT,
	payload: {
		data,
	},
});

export const fetchDataFeedQuery = (limit, offset, search) => ({
	type: types.DATA_FEED_QUERY,
	payload: {
		limit,
		offset,
		search,
	},
});

export const isFetchingDataFeed = (status) => ({
	type: types.IS_FETCHING_DATA_FEED,
	payload: {
		status,
	},
});

export const dataFeed = (datafeeds) => ({
	type: types.DATA_FEED,
	payload: {
		datafeeds,
	},
});

export const cancelAutoRenewPlan = (platform) => ({
	type: types.CANCEL_AUTO_RENEW_PLAN,
	payload: {
		platform,
	},
});

export const storeInvoicesStatusPaidSubscription = (invoices) => ({
	type: types.STORE_INVOICES_STATUS_PAID_SUBSCRIPTION,
	payload: invoices,
});

export const storeInvoicesStatusUnpaidSubscription = (invoices) => ({
	type: types.STORE_INVOICES_STATUS_UNPAID_SUBSCRIPTION,
	payload: invoices,
});

export const storeCurrentAppPlan = (plan) => ({
	type: types.STORE_CURRENT_APP_PLAN,
	payload: plan,
});

export const storeSummaryPlan = (percent) => ({
	type: types.STORE_SUMMARY_PLAN,
	payload: percent,
});

export const getInvoicePDF = (token) => ({
	type: types.GET_INVOICE_PDF,
	payload: { token },
});

export const IsShowCurrentUsageUpgradeDetail = (status) => ({
	type: types.IS_SHOW_CURRENT_USAGE_UPGRADE_DETAIL,
	payload: { status },
});

export const IsShowChatBot = (status, chatURL) => ({
	type: types.IS_SHOW_CHAT_BOT,
	payload: { status, chatURL },
});

export const isCancelAutoRenewPlan = (status) => ({
	type: types.IS_CANCEL_AUTO_RENEW_PLAN,
	payload: { status },
});

export const isShowingUnpaidInvoiceSummary = (status) => ({
	type: types.IS_SHOWING_UNPAID_INVOICE_SUMMARY,
	payload: { status },
});

export const selectedUnpiadInvoices = (invoice) => ({
	type: types.SELECTED_UNPAID_INVOICES,
	payload: { invoice },
});

export const selectedAllUnpiadInvoices = (invoices) => ({
	type: types.SELECTED_ALL_UNPAID_INVOICES,
	payload: { invoices },
});

export const isSelectedAllUnpiadInvoices = (status) => ({
	type: types.IS_SELECTED_ALL_UNPAID_INVOICES,
	payload: { status },
});

export const payingInvoiceStatusUnpaid = (paymentMethod, invoices) => ({
	type: types.PAYMENT_UNPAID_INVOICE,
	payload: { paymentMethod, invoices },
});

export const isPayingUnpaidInvoice = (status) => ({
	type: types.IS_PAYING_UNPAID_INVOICE,
	payload: { status },
});

export const invoiceActachFileChange = (id, url) => ({
	type: types.INVOICE_ACTACH_FILE_CHANGE,
	payload: { id, url },
});

export const onSubmitEditMessage = () => ({
	type: types.ON_SUBMIT_EDIT_MESSAGE,
});

export const onRemoveMessage = (node) => ({
	type: types.ON_REMOVE_MESSAGE,
	payload: {
		node,
	},
});

export const saveMessageConfig = () => ({
	type: types.SAVE_MESSAGE_CONFIG,
});

export const deployMessageConfig = () => ({
	type: types.DEPLOY_MESSAGE_CONFIG,
});

export const isDeploying = (isDeploying) => ({
	type: types.IS_DEPLOYING,
	payload: {
		isDeploying,
	},
});

export const savePreviewConfig = () => ({
	type: types.SAVE_PREVIEW_CONFIG,
});

export const savePreviewInBackground = (history) => ({
	type: types.SAVE_PREVIEW_IN_BACKGROUND,
	payload: {
		history,
	},
});

export const toggleSaveDiagramHistory = () => ({
	type: types.TOGGLE_SAVE_DIAGRAM_HISTORY,
});

export const saveDiagramHistoriesQuery = () => ({
	type: types.SAVE_DIAGRAM_HISTORIES_QUERY,
});

export const isFetchingSaveDiagramHistory = (status) => ({
	type: types.IS_FETCHING_SAVE_DIAGRAM_HISTORY,
	payload: {
		status,
	},
});

export const saveDiagramHistory = (saveDiagramHistory) => ({
	type: types.SAVE_DIAGRAM_HISTORIES,
	payload: {
		saveDiagramHistory,
	},
});

export const saveDiagramHistoriesUpdate = (id, data) => ({
	type: types.SAVE_DIAGRAM_HISTORIES_UPDATE,
	payload: {
		id,
		data,
	},
});

export const messageConfigBackupDiagramHistory = (messages) => ({
	type: types.MESSAGE_CONFIG_BACKUP_DIAGRAM_HISTORY,
	payload: {
		messages,
	},
});

export const previewSaveDiagramHistory = (messageConfig) => ({
	type: types.PREVIEW_SAVE_DIAGRAM_HISTORY,
	payload: {
		messageConfig,
	},
});

export const selectSaveDiagramHistory = (
	messageConfig,
	isFromPreview = false
) => ({
	type: types.SELECT_SAVE_DIAGRAM_HISTORY,
	payload: {
		messageConfig,
		isFromPreview,
	},
});

export const resetSaveDiagramHistory = () => ({
	type: types.RESET_SAVE_DIAGRAM_HISTORY,
});

export const storeUniqueID = (uniqueID) => ({
	type: types.STORE_UNIQUE_ID,
	payload: {
		uniqueID,
	},
});

export const saveMessageConfigNoHistory = (
	isAlert = true,
	isAutoSave = false
) => ({
	type: types.SAVE_MESSAGE_CONFIG_NO_HISTORY,
	payload: {
		isAlert,
		isAutoSave,
	},
});

export const platformSetting = (config) => ({
	type: types.PLATFORM_SETTING,
	payload: {
		config,
	},
});

export const platformUpload = (config) => ({
	type: types.PLATFORM_UPLOAD,
	payload: {
		config,
	},
});

export const fetchPlatformConfig = () => ({
	type: types.FETCH_PLATFORM_CONFIG,
});

export const isFetchingPlatformConfig = (status) => ({
	type: types.IS_FETCHING_PLATFORM_CONFIG,
	payload: {
		status,
	},
});

export const platformConfigErrors = (errors) => ({
	type: types.PLATFORM_CONFIG_ERRORS,
	payload: {
		errors,
	},
});

export const platformConfig = (config) => ({
	type: types.PLATFORM_CONFIG,
	payload: {
		config,
	},
});

export const savePlatformConfig = () => ({
	type: types.SAVE_PLATFORM_CONFIG,
});

export const refreshPlatformConfigToken = (platform) => ({
	type: types.REFRESH_PLATFORM_CONFIG_TOKEN,
	payload: {
		platform,
	},
});

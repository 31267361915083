import _ from "lodash";
import generateID from "../../../../utils/generateID";
import { useStoreDiagram } from "..";
import { advancedMessages } from "../../../../utils/config";
import { getToolDiagram } from "../global/diagram";

import {
    DIAGRAM_TYPE_FORCE_LAYOUT,
    DURATION_VIEW_PORT,
    nodeForceLayoutModes,
    nodeForceLayoutProps,
    nodeId,
    nodeModes,
} from "../../config";

export const createDraftForceLayoutNodeChild = (
    set,
    get,
    id,
    category,
    position
) => {
    try {
        if (
            getToolDiagram("getEdges")().filter((edge) => edge.source === id).length >
            0
        ) {
            return;
        }

        const selectedApp = window.store.system.selectedApp;
        const messages = [];
        const targetIds = [];

        Object.entries(advancedMessages)
            .filter(([key, message]) => key === category)
            .forEach(([key, category]) => {
                const supportedMessages = Object.entries(category.messages).filter(
                    ([key, message]) => {
                        let countTrue = 0;
                        for (let index = 0; index < selectedApp.platform.length; index++) {
                            const platformName = selectedApp.platform[index];
                            if (_.get(message, `platform.${platformName}`)) {
                                countTrue += 1;
                            }
                        }
                        return selectedApp.platform.length === countTrue;
                    }
                );

                supportedMessages
                    .filter(
                        ([key, message]) =>
                            key !== "INTRO" && key !== nodeModes.DIAGRAM_GROUP
                    )
                    .forEach(([key, message], index) => {
                        const childDraftForceLayout = {
                            ...nodeForceLayoutProps,
                            position,
                            id: `${nodeId.DRAFT}-${generateID()}`,
                            data: {
                                mode: nodeForceLayoutModes.CHILD,
                                slug: message.slug,
                                image: message.image,
                                icon: message.icon,
                            },
                        };

                        targetIds.push(childDraftForceLayout.id);
                        messages.push(childDraftForceLayout);
                    });
            });

        setTimeout(() => {
            getToolDiagram("setNodes")((nds) => nds.concat(...messages));
        }, 100);

        const sourceId = getToolDiagram("getNodes")().filter(
            (node) =>
                node.type === DIAGRAM_TYPE_FORCE_LAYOUT && node.data?.slug === category
        )[0].id;
        const edges = [];
        targetIds.forEach((targetId) => {
            if (targetId) {
                const source = {
                    nodeId: sourceId,
                };

                const target = {
                    nodeId: targetId,
                };

                edges.push({ source, target });
            }
        });
        setTimeout(() => {
            useStoreDiagram.getState().addEdgeForceLayout(edges);
        }, 100);

        const changeViewport = () => {
            const nodeCategoryForceLayout = getToolDiagram("getNodes")().filter(
                (node) =>
                    node.type === DIAGRAM_TYPE_FORCE_LAYOUT &&
                    node.data?.slug === category
            )[0];
            const zoom = 0.7;
            const x =
                -nodeCategoryForceLayout.position.x -
                -50 * zoom +
                window.innerWidth / 2 -
                zoom;
            const y =
                -nodeCategoryForceLayout.position.y -
                350 * zoom +
                window.innerHeight / 2 -
                zoom;

            const autoZoom = window.store.messageConfig.design.diagram.autoZoom;
            if (autoZoom) {
                getToolDiagram("setViewport")(
                    { x, y, zoom: zoom },
                    { duration: DURATION_VIEW_PORT }
                );
            }
        };

        for (let index = 1; index <= 5; index++) {
            setTimeout(() => {
                changeViewport();
            }, index * 500);
        }
    } catch (err) {
        console.error("[createDraftForceLayoutNodeChild] ", err);
    }
};

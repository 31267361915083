import * as actions from "../../../redux/actions";
import React, { useMemo } from "react";
import _ from "lodash";
import datatype from "./datatype";
import inputMode from "../../../utils/config/inputMode";

const GROBAL_VARS = [
	{
		key: "ms_00000",
		name: "GROBAL VARIABLES",
		isHeader: true,
		platforms: [
			"line",
			"web",
			"android",
			"ios",
			"whatsapp",
			"wechat",
			"messengerFacebook",
			"instagram",
			"telegram",
			"viber",
			"slack",
			"discord",
			"xTwitter",
			"kakao",
		],
	},
	{
		key: "ms_00000",
		label: "Name",
		field: "name",
		value: "Taylor swift",
		type: "string",
		format: "string",
		platforms: [
			"line",
			"whatsapp",
			"wechat",
			"messengerFacebook",
			"instagram",
			"telegram",
			"viber",
			"slack",
			"discord",
			"xTwitter",
			"kakao",
		],
	},
	{
		key: "ms_00000",
		label: "Total price",
		field: "price",
		value: "100.00",
		type: "number",
		format: "number",
		platforms: [
			"line",
			"web",
			"android",
			"ios",
			"whatsapp",
			"wechat",
			"messengerFacebook",
			"instagram",
			"telegram",
			"viber",
			"slack",
			"discord",
			"xTwitter",
			"kakao",
		],
	},
];

const Options = ({
	variables,
	search,
	onOptionSelected,
	messageId,
	selectedApp,
	dispatch,
}) => {
	const allVariables = useMemo(() => {
		const grobalVars = GROBAL_VARS.filter((v) => {
			return selectedApp.platform.every((item) => v.platforms.includes(item));
		});
		return [
			...grobalVars,
			{
				key: messageId,
				name: "CUSTOM VARIABLES",
				isHeader: true,
			},
			...Object.values(variables).map((value) => {
				const inputInterface = _.get(inputMode, `${value.mode}.${value.from}`);
				return {
					...inputInterface,
					key: value.nodeId,
					label: value.variable,
					field: value.variable,
					isCustomVar: true,
				};
			}),
		].filter((value) => {
			if (!search || value.isHeader) return true;
			return value.label?.search(new RegExp(search, "i")) >= 0;
		});
	}, [selectedApp, variables, search, messageId]);

	return allVariables.map((option, index) => {
		if (option.isHeader) {
			return (
				<span
					key={index}
					className="dropdown-item message-option message-option__header"
				>
					<span style={{ marginLeft: "0.7rem", color: "rgb(100, 100, 100)" }}>
						{option.name}
					</span>
				</span>
			);
		} else {
			return (
				<span
					key={index}
					className="dropdown-item message-option message-option__choice"
					onClick={() => onOptionSelected(option.key, option.field)}
				>
					<span className={`datatype-item ${option.type}`}>
						{datatype(option.type)}
					</span>
					<span className="answer-key">{option.label}</span>
					<span className="answer-value">{`${option.value}`}</span>
					<div style={{ flex: 1 }}></div>
					{option.isCustomVar && (
						<i
							className="fa fa-close"
							onClick={(e) => {
								e.stopPropagation();
								const newVariables = { ...variables };
								delete newVariables[option.field];
								dispatch(actions.variables(newVariables));
							}}
						></i>
					)}
				</span>
			);
		}
	});
};

export default Options;

import * as actions from "../actions";
import * as api from "../apis";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, take } from "redux-saga/effects";

function* refreshPlatformConfigToken() {
	while (true) {
		const {
			payload: { platform },
		} = yield take(types.REFRESH_PLATFORM_CONFIG_TOKEN)
		try {
			eventlog.info("refresh platform config token");

			yield call(api.refreshPlatformConfigToken, platform)
			const platformConfig = yield call(api.fetchPlatformConfig);

			yield put(actions.platformConfig(platformConfig));
		} catch (err) {
			console.error("[refreshPlatformConfigToken] ", err);

			eventlog.error("refresh platform config token", {
				message: err.message,
			});
		}
	}
}

export default refreshPlatformConfigToken;

import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { all, call, put, select, take } from "redux-saga/effects";

function* fetchDraftAppConfig() {
	try {
		const { data: app } = yield call(api.fetchSelectedApp)
		yield put(actions.draftAppConfig(app))
	} catch (err) {
		console.error('[fetchDraftAppConfig] ', err)
	}
}

function* fetchEditModalConfig() {
	try {
		const response = yield call(api.fetchEditModalConfig)
		yield put(actions.editModalConfig(response.data))
	} catch (err) {
		yield put(
			actions.editModalConfig({
				title: 'ARE YOU SURE?',
				description: 'This will delete your asnwer and take you back to the previous step.',
				cancel: 'CANCEL',
				confirm: 'EDIT',
			})
		)
		console.error('[fetchEditModalConfig] ', err)
	}
}

function* fetchECommerceConfig() {
	try {
		const response = yield call(api.fetchECommerceConfig)
		yield put(actions.ecommerceConfig(response.data))
	} catch (err) {
		console.error('[fetchECommerceConfig] ', err)
	}
}

function* fetchStartOver() {
	try {
		const response = yield call(api.fetchStartOver)
		yield put(actions.startOver(response.data))
	} catch (err) {
		yield put(
			actions.startOver({
				title: 'WANT TO START OVER?',
				description: 'This will clear your answers and start again from the beginning.',
				cancel: 'CANCEL',
				confirm: 'START OVER',
			})
		)
		console.error('[fetchStartOver] ', err)
	}
}

function* fetchTypingEmulation() {
	try {
		const response = yield call(api.fetchTypingEmulation)
		yield put(actions.typingEmulation(response.data))
	} catch (err) {
		console.error('[fetchTypingEmulation] ', err)
	}
}

function* fetchPlatformSetting() {
	try {
		const response = yield call(api.fetchPlatformSetting)
		yield put(actions.platformSetting(response.data))
	} catch (err) {
		console.error('[fetchPlatformSetting] ', err)
	}
}

function* fetchPlatformUpload() {
	try {
		const response = yield call(api.fetchPlatformUpload)
		yield put(actions.platformUpload(response.data))
	} catch (err) {
		console.error('[fetchPlatformUpload] ', err)
	}
}


function* fetchSettings() {
	while (true) {
		yield take(types.FETCH_SETTINGS)
		try {
			yield all([
				fetchDraftAppConfig(),
				fetchEditModalConfig(),
				fetchStartOver(),
				fetchTypingEmulation(),
				fetchECommerceConfig(),
				fetchPlatformSetting(),
				fetchPlatformUpload(),
			])
			yield put(actions.isConfigLoading(false))
		} catch (err) {
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')
			console.error('[fetchSettings] ', err)
			eventlog.error('fetch settings', {
				message: err.message
			})
		}
	}
}

export default fetchSettings

const simpleMessages = {
	"UNION-MESSAGE/END": {
		image: "/images/message-icons/finish.svg",
		name: "ENDING POINT",
		description: "For ending point",
		help: `**ENDING POINT**`,
	},
	"UNION-MESSAGE/MESSAGE": {
		image: "/images/message-icons/messenger.svg",
		name: "SEND A MESSAGE",
		description: "Text, images, videos…",
		help: `**SEND A MESSAGE**`,
	},
	"SINGLE-INPUT/NAME": {
		image: "/images/message-icons/boy.svg",
		name: "NAME",
		description: "Ask for the user name",
		help: `**NAME**`,
	},
	"SINGLE-INPUT/TEXT": {
		image: "/images/message-icons/document.svg",
		name: "TEXT",
		description: "Ask anything to the user",
		help: `**TEXT**`,
	},
	"SINGLE-INPUT/NUMBER": {
		image: "/images/message-icons/number.svg",
		name: "NUMBER",
		description: "Ask for a number",
		help: `**NUMBER**`,
	},
	"SINGLE-INPUT/EMAIL": {
		image: "/images/message-icons/email.svg",
		name: "EMAIL",
		description: "Ask for the user email",
		help: `**EMAIL**`,
	},
};

export default simpleMessages;

import * as actions from "../actions";
import * as api from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, select, take } from "redux-saga/effects";

function* savePlatformConfig() {
	while (true) {
		yield take(types.SAVE_PLATFORM_CONFIG)
		try {
			eventlog.info('save platform config')
			yield put(actions.updateSaveConfigLoading(true))

			const config = yield select(selectors.getPlatformConfig)
			yield call(api.savePlatformConfig, config)
			const platformConfig = yield call(api.fetchPlatformConfig)

			yield put(actions.platformConfig(platformConfig))

			const toastManager = yield select(selectors.getToast)
			toastManager.success('Saved successfully')

			// clear platform config errors
			yield put(actions.platformConfigErrors({}))
			yield put(actions.updateSaveConfigLoading(false))
		} catch (err) {
			console.error('[savePlatformConfig] ', err)
			if (err.response.data.status === 800) {
				yield put(
					actions.platformConfigErrors({
						token: 'Invalid token',
					})
				)
			}

			yield put(actions.updateSaveConfigLoading(false))
			const toastManager = yield select(selectors.getToast)
			toastManager.error('Got the error from server')

			eventlog.error('save platform config', {
				message: err.message
			})
		}
	}
}

export default savePlatformConfig

import * as actions from "../actions";
import * as apis from "../apis";
import * as selectors from "../selectors";
import * as types from "../types";
import eventlog from "../../modules/eventlog";
import { call, put, select, take } from "redux-saga/effects";
import { saveMessageConfig } from "./saveMessageConfig";

function* publishApp() {
	while (true) {
		yield take(types.PUBLISH_APP);
		try {
			eventlog.info("publish an app");
			yield put(actions.isPublishingApp(true));
			const app = yield select(selectors.getSelectedApp);

			const platformConfig = yield call(apis.fetchPlatformConfig);
			for (const platform of app.platform) {
				const platformData = platformConfig[platform];
				if (!platformData.access_token && !platformData.secret_key_hash) {
					yield put(
						actions.platformConfigErrors({
							secret: "Please fill in this field",
							token: "Please fill in this field",
						})
					);

					yield put(actions.isPublishingApp(false));
					yield put(actions.redirect(`/${app.slug}/config/platform-config`));
					return;
				}
			}

			yield* saveMessageConfig();
			yield call(apis.publishApp);

			const toastManager = yield select(selectors.getToast);
			toastManager.success("Publishing app, this will take around a minutes");

			yield put(actions.isPublishingApp(false));
		} catch (err) {
			const toastManager = yield select(selectors.getToast);
			if (err.message === "Request failed with status code 802") {
				toastManager.error("Current plan feature not support");
			}
			if (err.message === "Request failed with status code 803") {
				toastManager.error("Rich menu exceeded");
			} else {
				toastManager.error("Got the error from server");
			}
			yield put(actions.isPublishingApp(false));
			console.error("[publishApp] ", err);
			eventlog.error("publish app", {
				message: err.message,
			});
		}
	}
}

export default publishApp;
